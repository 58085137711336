.projects-container {
  width: 100%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 50px 50px;
}

.project-inner {
  width: 75%;
  margin: 0px auto;
}

.projects-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.projects-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 70%;
}

.projects-content {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  width: 100%;
  padding: 0px 30px;
  margin: auto;
}

.projects-content-mobile {
  display: none;
}

/* img left */
.projects-content-container-left {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 20px 0px;
  gap: 5px;
  position: relative;
}

.projects-content-container-left img {
  width: 100%;
  height: 410px;
}

.projects-content-container-left img:hover {
  filter: drop-shadow(5px 5px 10px blue);
  opacity: 0.5;
}

/* img right */
.projects-content-container-right {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 20px 0px;
  gap: 5px;
  position: relative;
}

.projects-content-container-right img {
  width: 100%;
  height: 410px;
}

.projects-content-container-right img:hover {
  filter: drop-shadow(5px 5px 10px blue);
  opacity: 0.5;
}

.project-cards-left {
  background-color: var(--card-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 55px;
  height: 250px;
  position: absolute;
  left: 550px;
  overflow: auto;
  border-radius: 1%;
}

.project-cards-right {
  background-color: var(--card-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 55px;
  height: 250px;
  position: absolute;
  right: 550px;
  overflow: auto;
  border-radius: 1%;
  width: 55%;
}

.projects-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#text-large {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

#text-small {
  font-size: 0.8rem;
  margin-top: 20px;
}

#text-mid {
  font-size: 0.8rem;
  margin-top: 10px;
}

.project-tech-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.9rem;
  color: var(--highlight-color);
}

.project-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-card {
  background-color: var(--card-color);
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 240px;
  border-radius: 5%;
  padding: 15px;
}

.mobile-card-header {
  margin-top: 10px;
  display: block;
}

.mobile-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-card-body {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 10px;
}

.mobile-card-body img {
  width: 100%;
  height: 100px;
}

.mobile-card-h1 {
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
}

.mobile-card-h2 {
  font-size: 10px;
  text-align: left;
  letter-spacing: 1.5px;
}

.mobile-card-link-text {
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 2.5px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

#mobile-card-h3 {
  margin-top: 10px;
  font-size: 10px;
  color: var(--highlight-color);
}

.mobile-card-tech-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.mobile-card-tech-list > p {
  font-size: 2px;
}

@media screen and (max-width: 1550px) {
  .projects-details {
    display: block;
    padding: 5px;
  }

  .projects-horizontal-bar {
    display: none;
  }

  .projects-heading-container {
    display: block;
    text-align: center;
  }

  .project-inner {
    width: 100%;
  }

  .projects-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    border-bottom: 40px;
  }

  .projects-heading-container {
    margin-bottom: 0px;
  }

  .projects-content {
    display: none;
  }

  .projects-content-mobile {
    display: block;
    height: calc(90vh - 200px);
    overflow-y: scroll;
  }

  .project-tech-list {
    display: block;
  }

  .projects-content-container-left img {
    display: none;
  }

  .projects-content-container-left {
    display: block;
  }

  .project-cards-left {
    position: static;
    height: 320px;
    width: 100%;
  }

  .projects-content-container-right img {
    display: none;
  }

  .projects-content-container-right {
    display: block;
  }

  .project-cards-right {
    position: static;
    height: 320px;
    width: 100%;
  }

  .projects-content::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .projects-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
