@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  background-color: var(--main-color);
  letter-spacing: 2.5px;
  color: var(--secondary-color);
}

:root {
  --navscroll-color: #020c1b;
  --main-color: #0a192f;
  --primary-color: #e6f1ff;
  --secondary-color: #a8b2d1;
  --highlight-color: #64ffda;
  --card-color: #112240;
}

/* scroll bar starts */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--main-color);
}

::-webkit-scrollbar-thumb {
  background: var(--highlight-color);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* scroll bar ends */

.main-container {
  width: 100%;
}

.inner-left-container {
  z-index: 1;
  position: fixed;
  left: 5px;
  bottom: 0;
  width: 50px;
  height: calc(100vh - 60px);
}

.inner-center-container {
  margin: 0px 20px;
  width: calc(100% - 150px);
  margin: auto;
}

.component-main-container {
  height: calc(100vh - 80px);
  padding-top: 200px;
}

.component-main-container-extra {
  height: calc(100vh - 80px);
  padding-top: 80px;
}

.inner-right-container {
  position: fixed;
  width: 50px;
  right: 5px;
  bottom: 0;
  height: calc(100vh - 60px);
}

/* utility class starts */
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--secondary-color);
}

#section-heading {
  font-size: 25px;
  font-weight: bold;
  color: var(--primary-color);
}

#section-secondary-heading {
  font-size: 16px;
  color: var(--secondary-color);
  margin-top: 30px;
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* utility class ends */

@media screen and (max-width: 1100px) {
  .inner-center-container {
    width: 95%;
    margin: auto;
    height: calc(100% - 60px);
  }

  .component-main-container {
    height: auto;
    padding-top: 50px;
  }

  .component-main-container-extra {
    height: calc(100vh - 80px);
    padding-top: 20px;
    margin-bottom: 20px;
  }
}
