.skills-container {
  width: 80%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 80px 50px;
}

.skills-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skills-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 80%;
}

.skills-content-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 50px 0px;
  gap: 20px;
}

.card {
  padding: 70px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--card-color);
  text-align: center;
  color: var(--primary-color);
  transition: transform 1s;
  border-radius: 20px;
}

.card #skills-heading {
  font-size: 25px;
  background-color: var(--card-color);
  font-weight: bold;
  text-shadow: 3px 4px 10px var(--main-color);
}

.card #language-list {
  background-color: var(--card-color);
  margin-top: 20px;
}

.card img {
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.card:hover {
  opacity: 0.7;
  transform: translateY(-20px);
}

.tools-list {
  background-color: var(--card-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.tools-list p {
  font-size: 13px;
  color: var(--highlight-color);
}

@media screen and (max-width: 1100px) {
  .skills-content-container {
    display: block;
    padding: 5px;
  }

  .skills-horizontal-bar {
    display: none;
  }

  .skills-heading-container {
    display: block;
    text-align: center;
  }

  .skills-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
  }

  .card {
    margin-top: 20px;
    padding: 30px;
  }

  .card img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  #language-list{
    font-size: 10px;
  }
}
