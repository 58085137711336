.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 20px;
  flex-wrap: wrap;
  position: sticky;
  z-index: 100;
  top: 0;
}

.navbar-scroll{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 20px;
  flex-wrap: wrap;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: var(--card-color);
}

#hamburger {
  display: none;
}

#logoName {
  font-size: 1.4rem;
  font-weight: bold;
}

#logoName::first-letter {
  color: var(--highlight-color);
  font-weight: bold;
  font-size: 2rem;
}

.right-navbar ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-navbar ul a {
  margin-left: 30px;
  font-size: 0.9rem;
}

a:hover {
  color: var(--highlight-color);
}

.mobile-navbar{
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  height: 100vh;
  backdrop-filter: blur(10px);
  padding: 20px;
}

.mobile-navbar .mobile-close{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile-navbar .mobile-links{
  margin-top: 50px;
  text-align: center;
  line-height: 3;
}

@media screen and (max-width: 768px) {
  .right-navbar {
    display: none;
  }

  #hamburger {
    display: block;
  }
}
