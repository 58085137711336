.experience-container {
  width: 75%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 80px 50px;
}

.experience-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.experience-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 70%;
}

.experience-content-container {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 50px 0px;
  gap: 20px;
}

.company-div {
  padding: 0px 0px;
  /* vertical line in where i've worked container */
  border-left: 1px solid var(--secondary-color);
  height: 320px;
}

.company-div p {
  margin-bottom: 20px;
  cursor: pointer;
}

#company-not-active {
  background-color: var(--main-color);
  color: var(--highlight-color);
  padding: 20px;
}

#company-active {
  background-color: var(--card-color);
  color: var(--highlight-color);
  padding: 20px;
}

.company-details {
  padding: 20px;
  color: var(--primary-color);
}

#text-sm {
  color: var(--secondary-color);
  font-size: 0.9rem;
}

.company-details-point {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-top: 20px;
  line-height: 3.5;
}

.company-details-active {
  display: block;
}

.company-details-not-active {
  display: none;
}

@media screen and (max-width: 1100px) {
  .experience-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
  }

  .experience-heading-container {
    display: block;
  }

  .experience-horizontal-bar {
    display: none;
  }

  .experience-content-container {
    grid-template-columns: 100%;
    padding: 5px;
  }

  .company-details-point {
    line-height: 3;
    font-size: 0.8rem;
    text-align: left;
  }
}
