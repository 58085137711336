.right-side {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.vertical-bar {
  height: 150px;
  border: 1px solid white;
  width: 0%;
  margin: 20px auto 0px;
}

.icons {
  font-size: 30px;
  margin: 10px;
}

#email {
  writing-mode: vertical-rl;
}

@media screen and (max-width: 1080px) {
  .right-side {
    display: none;
  }
}
