.innerdiv {
  width: 75%;
  margin: 0px auto;
  line-height: 1.5;
  border: 1px solid var(--highlight-color);
  padding: 80px 50px;
}

.innerdiv p {
  letter-spacing: 2px;
  color: var(--highlight-color);
}

#name {
  font-size: 4rem;
  font-weight: bold;
  color: var(--primary-color);
}

#short-bio {
  color: var(--secondary-color);
  font-size: 3rem;
  font-weight: bold;
}

#text-md {
  color: var(--secondary-color);
}

#btn {
  margin-top: 30px;
  padding: 10px 40px;
  border: 1px solid var(--highlight-color);
  background-color: var(--main-color);
  color: var(--secondary-color);
}

#btn:hover {
  transform: scale(1.1, 1.1);
  color: var(--primary-color);
}

/* text animation */
.innerdiv p:nth-child(1) {
  animation: fadeIn 3s;
}

.innerdiv p:nth-child(2) {
  animation: fadeIn 4s;
}

.innerdiv p:nth-child(3) {
  animation: fadeIn 5s;
}

.innerdiv p:nth-child(5) {
  animation: fadeIn 6s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1100px) {
  .innerdiv {
    width: 100%;
    padding: 20px;
    border: none;
  }

  .innerdiv p {
    letter-spacing: 1px;
  }

  #short-bio {
    font-size: 2rem;
  }

  #btn {
    width: 100%;
  }

  #name {
    font-size: 3rem;
  }
}
