.about-container {
  width: 75%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 80px 50px;
}

.about-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 80%;
}

.about-content-container {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 50px 0px;
  gap: 20px;
}

.image-div {
  border: 1px solid var(--highlight-color);
  position: relative;
  height: 300px;
  width: 320px;
  transition: transform 1s;
}

.image-div:hover {
  transform: scale(0.9, 0.9);
}

#about-image {
  position: absolute;
  height: 300px;
  width: 300px;
  top: -20px;
  left: -10px;
}

.about-links {
  width: 30%;
  margin: 20px auto 0px;
  display: none;
}

@media screen and (max-width: 1100px) {
  .about-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
  }

  .about-heading-container {
    display: block;
  }

  .about-content-container {
    grid-template-columns: 100%;
    padding: 5px;
  }

  .about-horizontal-bar {
    display: none;
  }

  .image-div {
    height: 300px;
    width: 320px;
    margin: 15px auto 0px;
  }
  

  .about-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
