@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  background-color: #0a192f;
  background-color: var(--main-color);
  letter-spacing: 2.5px;
  color: #a8b2d1;
  color: var(--secondary-color);
}

:root {
  --navscroll-color: #020c1b;
  --main-color: #0a192f;
  --primary-color: #e6f1ff;
  --secondary-color: #a8b2d1;
  --highlight-color: #64ffda;
  --card-color: #112240;
}

/* scroll bar starts */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #0a192f;
  background: var(--main-color);
}

::-webkit-scrollbar-thumb {
  background: #64ffda;
  background: var(--highlight-color);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* scroll bar ends */

.main-container {
  width: 100%;
}

.inner-left-container {
  z-index: 1;
  position: fixed;
  left: 5px;
  bottom: 0;
  width: 50px;
  height: calc(100vh - 60px);
}

.inner-center-container {
  margin: 0px 20px;
  width: calc(100% - 150px);
  margin: auto;
}

.component-main-container {
  height: calc(100vh - 80px);
  padding-top: 200px;
}

.component-main-container-extra {
  height: calc(100vh - 80px);
  padding-top: 80px;
}

.inner-right-container {
  position: fixed;
  width: 50px;
  right: 5px;
  bottom: 0;
  height: calc(100vh - 60px);
}

/* utility class starts */
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #a8b2d1;
  color: var(--secondary-color);
}

#section-heading {
  font-size: 25px;
  font-weight: bold;
  color: #e6f1ff;
  color: var(--primary-color);
}

#section-secondary-heading {
  font-size: 16px;
  color: #a8b2d1;
  color: var(--secondary-color);
  margin-top: 30px;
  -webkit-animation: fadeIn 2s;
          animation: fadeIn 2s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* utility class ends */

@media screen and (max-width: 1100px) {
  .inner-center-container {
    width: 95%;
    margin: auto;
    height: calc(100% - 60px);
  }

  .component-main-container {
    height: auto;
    padding-top: 50px;
  }

  .component-main-container-extra {
    height: calc(100vh - 80px);
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 20px;
  flex-wrap: wrap;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
}

.navbar-scroll{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 20px;
  flex-wrap: wrap;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: var(--card-color);
}

#hamburger {
  display: none;
}

#logoName {
  font-size: 1.4rem;
  font-weight: bold;
}

#logoName::first-letter {
  color: var(--highlight-color);
  font-weight: bold;
  font-size: 2rem;
}

.right-navbar ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-navbar ul a {
  margin-left: 30px;
  font-size: 0.9rem;
}

a:hover {
  color: var(--highlight-color);
}

.mobile-navbar{
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  height: 100vh;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 20px;
}

.mobile-navbar .mobile-close{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile-navbar .mobile-links{
  margin-top: 50px;
  text-align: center;
  line-height: 3;
}

@media screen and (max-width: 768px) {
  .right-navbar {
    display: none;
  }

  #hamburger {
    display: block;
  }
}

.left-side {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}

.left-side a{
  padding-top: 20px;
}

.vertical-bar {
  height: 150px;
  border: 1px solid white;
  width: 0%;
  margin: 20px auto 0px;
}

@media screen and (max-width: 1080px) {
  .left-side {
    display: none;
  }
}

.innerdiv {
  width: 75%;
  margin: 0px auto;
  line-height: 1.5;
  border: 1px solid var(--highlight-color);
  padding: 80px 50px;
}

.innerdiv p {
  letter-spacing: 2px;
  color: var(--highlight-color);
}

#name {
  font-size: 4rem;
  font-weight: bold;
  color: var(--primary-color);
}

#short-bio {
  color: var(--secondary-color);
  font-size: 3rem;
  font-weight: bold;
}

#text-md {
  color: var(--secondary-color);
}

#btn {
  margin-top: 30px;
  padding: 10px 40px;
  border: 1px solid var(--highlight-color);
  background-color: var(--main-color);
  color: var(--secondary-color);
}

#btn:hover {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
  color: var(--primary-color);
}

/* text animation */
.innerdiv p:nth-child(1) {
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}

.innerdiv p:nth-child(2) {
  -webkit-animation: fadeIn 4s;
          animation: fadeIn 4s;
}

.innerdiv p:nth-child(3) {
  -webkit-animation: fadeIn 5s;
          animation: fadeIn 5s;
}

.innerdiv p:nth-child(5) {
  -webkit-animation: fadeIn 6s;
          animation: fadeIn 6s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1100px) {
  .innerdiv {
    width: 100%;
    padding: 20px;
    border: none;
  }

  .innerdiv p {
    letter-spacing: 1px;
  }

  #short-bio {
    font-size: 2rem;
  }

  #btn {
    width: 100%;
  }

  #name {
    font-size: 3rem;
  }
}

.right-side {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.vertical-bar {
  height: 150px;
  border: 1px solid white;
  width: 0%;
  margin: 20px auto 0px;
}

.icons {
  font-size: 30px;
  margin: 10px;
}

#email {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
}

@media screen and (max-width: 1080px) {
  .right-side {
    display: none;
  }
}

.about-container {
  width: 75%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 80px 50px;
}

.about-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 80%;
}

.about-content-container {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 50px 0px;
  grid-gap: 20px;
  gap: 20px;
}

.image-div {
  border: 1px solid var(--highlight-color);
  position: relative;
  height: 300px;
  width: 320px;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.image-div:hover {
  -webkit-transform: scale(0.9, 0.9);
          transform: scale(0.9, 0.9);
}

#about-image {
  position: absolute;
  height: 300px;
  width: 300px;
  top: -20px;
  left: -10px;
}

.about-links {
  width: 30%;
  margin: 20px auto 0px;
  display: none;
}

@media screen and (max-width: 1100px) {
  .about-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
  }

  .about-heading-container {
    display: block;
  }

  .about-content-container {
    grid-template-columns: 100%;
    padding: 5px;
  }

  .about-horizontal-bar {
    display: none;
  }

  .image-div {
    height: 300px;
    width: 320px;
    margin: 15px auto 0px;
  }
  

  .about-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.skills-container {
  width: 80%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 80px 50px;
}

.skills-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skills-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 80%;
}

.skills-content-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 50px 0px;
  grid-gap: 20px;
  gap: 20px;
}

.card {
  padding: 70px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--card-color);
  text-align: center;
  color: var(--primary-color);
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  border-radius: 20px;
}

.card #skills-heading {
  font-size: 25px;
  background-color: var(--card-color);
  font-weight: bold;
  text-shadow: 3px 4px 10px var(--main-color);
}

.card #language-list {
  background-color: var(--card-color);
  margin-top: 20px;
}

.card img {
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.card:hover {
  opacity: 0.7;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.tools-list {
  background-color: var(--card-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.tools-list p {
  font-size: 13px;
  color: var(--highlight-color);
}

@media screen and (max-width: 1100px) {
  .skills-content-container {
    display: block;
    padding: 5px;
  }

  .skills-horizontal-bar {
    display: none;
  }

  .skills-heading-container {
    display: block;
    text-align: center;
  }

  .skills-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
  }

  .card {
    margin-top: 20px;
    padding: 30px;
  }

  .card img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  #language-list{
    font-size: 10px;
  }
}

.experience-container {
  width: 75%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 80px 50px;
}

.experience-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.experience-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 70%;
}

.experience-content-container {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 50px 0px;
  grid-gap: 20px;
  gap: 20px;
}

.company-div {
  padding: 0px 0px;
  /* vertical line in where i've worked container */
  border-left: 1px solid var(--secondary-color);
  height: 320px;
}

.company-div p {
  margin-bottom: 20px;
  cursor: pointer;
}

#company-not-active {
  background-color: var(--main-color);
  color: var(--highlight-color);
  padding: 20px;
}

#company-active {
  background-color: var(--card-color);
  color: var(--highlight-color);
  padding: 20px;
}

.company-details {
  padding: 20px;
  color: var(--primary-color);
}

#text-sm {
  color: var(--secondary-color);
  font-size: 0.9rem;
}

.company-details-point {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-top: 20px;
  line-height: 3.5;
}

.company-details-active {
  display: block;
}

.company-details-not-active {
  display: none;
}

@media screen and (max-width: 1100px) {
  .experience-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    text-align: center;
  }

  .experience-heading-container {
    display: block;
  }

  .experience-horizontal-bar {
    display: none;
  }

  .experience-content-container {
    grid-template-columns: 100%;
    padding: 5px;
  }

  .company-details-point {
    line-height: 3;
    font-size: 0.8rem;
    text-align: left;
  }
}

.projects-container {
  width: 100%;
  margin: 0px auto;
  line-height: 1.5;
  padding: 50px 50px;
}

.project-inner {
  width: 75%;
  margin: 0px auto;
}

.projects-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.projects-horizontal-bar {
  border: 0.8px solid var(--secondary-color);
  width: 70%;
}

.projects-content {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  width: 100%;
  padding: 0px 30px;
  margin: auto;
}

.projects-content-mobile {
  display: none;
}

/* img left */
.projects-content-container-left {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 20px 0px;
  grid-gap: 5px;
  gap: 5px;
  position: relative;
}

.projects-content-container-left img {
  width: 100%;
  height: 410px;
}

.projects-content-container-left img:hover {
  -webkit-filter: drop-shadow(5px 5px 10px blue);
          filter: drop-shadow(5px 5px 10px blue);
  opacity: 0.5;
}

/* img right */
.projects-content-container-right {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 20px 0px;
  grid-gap: 5px;
  gap: 5px;
  position: relative;
}

.projects-content-container-right img {
  width: 100%;
  height: 410px;
}

.projects-content-container-right img:hover {
  -webkit-filter: drop-shadow(5px 5px 10px blue);
          filter: drop-shadow(5px 5px 10px blue);
  opacity: 0.5;
}

.project-cards-left {
  background-color: var(--card-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 55px;
  height: 250px;
  position: absolute;
  left: 550px;
  overflow: auto;
  border-radius: 1%;
}

.project-cards-right {
  background-color: var(--card-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 55px;
  height: 250px;
  position: absolute;
  right: 550px;
  overflow: auto;
  border-radius: 1%;
  width: 55%;
}

.projects-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#text-large {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

#text-small {
  font-size: 0.8rem;
  margin-top: 20px;
}

#text-mid {
  font-size: 0.8rem;
  margin-top: 10px;
}

.project-tech-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.9rem;
  color: var(--highlight-color);
}

.project-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-card {
  background-color: var(--card-color);
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 240px;
  border-radius: 5%;
  padding: 15px;
}

.mobile-card-header {
  margin-top: 10px;
  display: block;
}

.mobile-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-card-body {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 10px;
}

.mobile-card-body img {
  width: 100%;
  height: 100px;
}

.mobile-card-h1 {
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
}

.mobile-card-h2 {
  font-size: 10px;
  text-align: left;
  letter-spacing: 1.5px;
}

.mobile-card-link-text {
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 2.5px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

#mobile-card-h3 {
  margin-top: 10px;
  font-size: 10px;
  color: var(--highlight-color);
}

.mobile-card-tech-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.mobile-card-tech-list > p {
  font-size: 2px;
}

@media screen and (max-width: 1550px) {
  .projects-details {
    display: block;
    padding: 5px;
  }

  .projects-horizontal-bar {
    display: none;
  }

  .projects-heading-container {
    display: block;
    text-align: center;
  }

  .project-inner {
    width: 100%;
  }

  .projects-container {
    width: 100%;
    margin: 100px auto 0px;
    line-height: 1.5;
    padding: 0px 10px;
    border-bottom: 40px;
  }

  .projects-heading-container {
    margin-bottom: 0px;
  }

  .projects-content {
    display: none;
  }

  .projects-content-mobile {
    display: block;
    height: calc(90vh - 200px);
    overflow-y: scroll;
  }

  .project-tech-list {
    display: block;
  }

  .projects-content-container-left img {
    display: none;
  }

  .projects-content-container-left {
    display: block;
  }

  .project-cards-left {
    position: static;
    height: 320px;
    width: 100%;
  }

  .projects-content-container-right img {
    display: none;
  }

  .projects-content-container-right {
    display: block;
  }

  .project-cards-right {
    position: static;
    height: 320px;
    width: 100%;
  }

  .projects-content::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .projects-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

